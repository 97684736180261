<template>
  <div class="service-card" @click="handleSelectService">
    <!-- icon -->
    <div
      class="service-card-icon"
      :style="{ background: serviceStyles[service.icon].background }"
    >
      <div
        :class="serviceStyles[service.icon].iconName"
        :style="{ color: serviceStyles[service.icon].color }"
      />
    </div>
    <!-- content -->
    <div class="service-card-content">
      <div class="service-card-content-info">
        <div
          class="content-title service-card-content-info-title"
          v-html="cardText(service.title)"
        />

        <div class="service-card-content-info-data">
          <div class="content-title duration" v-text="service.duration" />
          <!-- Growthbook - Version 3 -->
          <div
            v-if="version === 2 && service.max_package_discount > 0"
            class="service-card-content-description body-small"
            v-text="
              $translations['specialist-page'].price['service-card'][
                'unit-cost'
              ]
            "
          />

          <template v-if="allowedByRole([roleType.USER, roleType.USER_B2B])">
            <div
              class="content-title"
              :class="priceStyle"
              v-text="service.original_price"
            />
          </template>
        </div>
      </div>

      <div class="icon-arrow-big-outline-right action" v-if="action" />

      <!-- final price & promo -->
      <div
        class="service-card-content-promo"
        v-if="
          service.chip?.text &&
          service.original_price !== service.price &&
          allowedByRole([roleType.USER, roleType.USER_B2B])
        "
      >
        <Chip
          class="chip"
          size="small"
          :color="chipColor"
          :text="service.chip.text"
        />

        <!-- promo type -->
        <UsagesCoins
          v-if="service.package.uses_left > 0"
          :option="service"
          :iconName="service.icon"
          :max="service.package.max_uses"
          :remaining="service.package.uses_left"
        />
        <div v-else class="content-title price" v-text="service.price" />
      </div>
      <!-- Growthbook - Version 3 -->
      <div
        v-if="version === 2 && service.max_package_discount > 0"
        class="service-card-content-package"
      >
        <div
          class="body-small"
          v-text="
            $translations['specialist-page'].price['service-card'][
              'packages-cost'
            ]
          "
        />
        <div class="content-title price" v-text="packagePrice" />
      </div>

      <div
        class="service-card-content-description body-small"
        v-text="service.description"
      />

      <!-- Growthbook - Version 2 chip -->
      <Chip
        v-if="version === 1 && service.max_package_discount > 0"
        class="chip-version"
        color="blue"
        :text="chipText"
      />
    </div>
  </div>
</template>

<script>
import UsagesCoins from '@/components/UsagesCoins'
import { Chip, ServiceIcons, RoleMixin } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'ServiceCard',
  components: {
    UsagesCoins,
    Chip
  },
  mixins: [RoleMixin],
  props: {
    service: Object,
    active: Boolean,
    specialistPublic: Boolean,
    action: Function,
    version: Number
  },
  data () {
    return {
      serviceStyles: ServiceIcons
    }
  },
  mounted () {
    if (this.service.id === this.$route.query.service_id) {
      this.onOptionSelected(this.service)
    }

    if (this.service.is_default) {
      this.$emit('changed', this.service)
    }
  },
  methods: {
    onOptionSelected (option) {
      if (option === this.service) {
        return
      }

      this.service = option
      this.$emit('changed', option)

      if (this.action.callback) {
        this.action.callback()
      }
    },
    handleSelectService () {
      this.$emit('changed', this.service)
    },
    divideTextIntoTwoLines (text) {
      const textArray = text.split(' ')
      const firstLine = textArray
        .slice(0, Math.ceil(textArray.length / 2))
        .join(' ')
      const secondLine = textArray
        .slice(Math.ceil(textArray.length / 2))
        .join(' ')
      return `${firstLine}<br>${secondLine}`
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role'
    }),
    chipText () {
      const discount =
        this.service.original_price - this.service.max_package_discount
      return this.$translations['specialist-page'].price[
        'service-card'
      ].chip.replace('{savings}', discount)
    },
    chipColor () {
      const color = this.service?.chip?.color?.toLowerCase()
      return color
    },
    priceStyle () {
      if (this.service.price !== this.service.original_price) {
        return 'old-price'
      } else {
        return ''
      }
    },
    packagePrice () {
      const pkgPrice = this.service.package.max_package_discount
      return this.$translations['specialist-page'].price[
        'service-card'
      ].from.replace('{discount}', pkgPrice)
    },
    cardText () {
      return (text) => {
        return this.divideTextIntoTwoLines(text)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.service-card
  display: flex
  padding: 8px 16px 12px 12px
  background: var(--gray-5)
  border-radius: 16px
  gap: 8px
  &-icon
    margin-top: 4px
    min-width: 24px
    height: 24px
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
  &-content
    gap: 4px
    display: flex
    flex-direction: column
    width: 100%
    &-info
      display: flex
      justify-content: space-between
      &-title
        align-self: center
      &-data
        text-align: right
        .duration
          color: var(--dark-purple-60)
        .old-price
          color: var(--gray-50)
          text-decoration: line-through
          text-decoration-color: var(--gray-40)
    &-package
      text-align: right
    &-promo
      display: flex
      justify-content: space-between

.chip-version
  justify-content: center
  align-self: center
  width: 100%
  width: -webkit-fill-available
  ::v-deep .body-small
    color: var(--blue-120)!important

::v-deep .chip-purple
  background: var(--purple-10)
</style>
