<template>
  <div class="packages-banner padding-all">
    <div class="max-width">
      <div
        class="packages-banner-content content-title"
        v-html="packageData.description"
      />
      <Button
        class="packages-banner-button text-white heading-title"
        color="green"
        :text="$translations['specialist-page'].price['package-banner'].button"
        @clicked="goToPackageView"
      />
      <img class="logo" :src="imgSrc" alt="Saving money" />
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'

export default {
  name: 'PackagesBanner',
  components: { Button },
  props: {
    packageData: Object,
    specialist: Object
  },
  data () {
    return {
      imgSrc:
        'https://s3.amazonaws.com/assets.selia.co/crosseling-banner/saving-money.png'
    }
  },
  methods: {
    goToPackageView () {
      this.$router.push({
        name: 'Packages',
        query: {
          specialists: JSON.stringify([{
            id: this.$route.params.id || this.specialist.id,
            name: this.specialist.about_me?.name || this.specialist.name,
            picture: this.specialist.about_me?.picture || this.specialist.picture
          }]),
          back: this.$route.fullPath
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.skeleton-container
  margin-bottom: 8px
.packages-banner
  background: var(--green-20)
  width: 100%
  position: relative
  max-width: 1040px
  margin: 8px auto
  border-radius: 16px
  .max-width
    position: relative
    margin: 0 auto
    max-width: 480px
    .logo
      position: absolute
      width: 96px
      height: 96px
      bottom: -16px
      right: 0
  &-header
    display: flex
    align-items: center
    gap: 8px
  &-content
    margin: 8px 0
  &-button
    padding: 4px 8px
    height: 32px
    white-space: nowrap
    width: min-content
    z-index: 2
  &-img
    position: absolute

@media (min-width: 840px)
  .packages-banner
    &-button
      height: 48px
      padding: 12px 24px
</style>
