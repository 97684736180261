<template>
  <div :style="setStyles">
    <!-- text -->
    <div
      v-if="text"
      class="body text-gray-60"
      :class="{ 'body-clamp': showClamp && shouldClamp }"
      v-text="text"
    />
    <!-- item -->
    <div v-if="items.length >= 1" class="items-container margin-top">
      <div
        v-for="(item, key) in getItems"
        :key="key"
        :class="{ item: !extendedItem, 'item-extended': extendedItem }"
      >
        <template v-if="extendedItem">
          <!-- item name -->
          <div class="content-title item-name" v-text="item.name" />
          <!-- item description -->
          <div class="body-small item-name" v-text="item.description" />
          <hr
            class="margin-y-xs"
            v-if="getItems.length > 1 && key !== getItems.length - 1"
          />
        </template>
        <template v-else>
          <!-- icon or image -->
          <img v-if="countryFlag" :src="flag(item)" class="item-image" alt="" />
          <div
            v-else
            :class="iconName"
            :style="{ color: iconColor }"
            class="item-icon"
          />

          <!-- item name -->
          <div
            v-if="countryFlag"
            class="body item-name"
            v-text="$translations.languages[item]"
          />
          <div v-else class="body item-name" v-text="item.name" />
          <!-- tooltip -->
          <span
            v-if="item.description"
            v-tooltip="item.description"
            class="icon-question-mark-off tooltip-icon"
          />
        </template>
      </div>
    </div>

    <!-- clamp -->
    <Button
      v-if="shouldClamp"
      type="link"
      :text-color="textColor"
      class="button-clamp"
      :text="sectionText"
      @clicked="handleClamb"
    />
  </div>
</template>

<script>
import { FLAGS, tooltip, Button } from '@seliaco/red-panda'

export default {
  name: 'SectionClamp',
  components: { Button },
  directives: { tooltip },
  props: {
    textColor: String,
    text: String,
    extendedItem: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    iconName: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    countryFlag: {
      type: Boolean,
      default: false
    },
    maxItems: {
      type: Number,
      default: 4
    },
    maxLines: {
      type: Number,
      default: 4
    },
    expandTextValues: {
      type: Array,
      default: () => ['see-more', 'see-less']
    }
  },
  data () {
    return {
      showClamp: false,
      shouldClamp: false,
      lineHeight: null,
      el: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$nextTick(() => {
        this.el = this.$el
        this.showClamp = true
        this.lineHeight = null
        this.handlerShouldClamp()
      })
    },
    handlerShouldClamp () {
      if (this.items.length > this.maxItems) {
        this.shouldClamp = true
      } else if (this.el.children[0] && !this.items.length) {
        if (this.lineHeight === null) {
          this.changeLineHeight()
        }
        const paragraphHeight = this.el.children[0].clientHeight
        const maxParagraphHeight = this.lineHeight * this.maxLines

        this.shouldClamp = paragraphHeight > maxParagraphHeight
      } else {
        this.shouldClamp = false
      }
    },
    changeLineHeight () {
      this.lineHeight = parseInt(
        getComputedStyle(this.el.children[0]).lineHeight
      )
    },
    handleClamb () {
      this.showClamp = !this.showClamp
    },
    flag (lang) {
      const languageCodes = this.$languageCodes.find(
        (option) => option.name === lang
      )

      return FLAGS[`${languageCodes.flag}Flag`]
    }
  },
  computed: {
    sectionText () {
      return this.$translations.general[
        this.showClamp ? this.expandTextValues[0] : this.expandTextValues[1]
      ]
    },
    getItems () {
      return this.showClamp ? this.getTruncateItems : this.items
    },
    getTruncateItems () {
      let numberOfItems = 0
      const newItems = []

      this.items.map((item) => {
        numberOfItems++
        if (numberOfItems <= this.maxItems) {
          newItems.push(item)
        }
      })
      return newItems
    },
    setStyles () {
      return {
        '--clamp': this.maxLines
      }
    }
  },
  watch: {
    text: {
      handler () {
        this.init()
      }
    },
    items: {
      handler () {
        this.init()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.button-clamp
  height: auto
  padding-bottom: 0
  padding-right: 0
  margin-bottom: 2px
  width: fit-content
  margin-left: auto
.items-container
  display: flex
  width: 100%
  flex-direction: column
  gap: 8px
  align-items: self-start
  .item
    width: 100%
    display: flex
    gap: 8px
    &-icon,
    &-tooltip,
    &-image
      font-size: var(--xl)
      align-self: center
    &-name
      width: inherit
    &-image
      width: var(--xl)
      height: var(--xl)
      border-radius: 5px
    .tooltip-icon
      color: var(--gray-40)
      font-size: var(--xl)
  .item-extended
    display: flex
    flex-direction: column
    gap: 2px
    width: 100%
.body-clamp
  display: -webkit-box
  line-height: 1.5em
  max-height: calc(5 * 1.5em)
  overflow: hidden
  -webkit-line-clamp: var(--clamp)
  -webkit-box-orient: vertical
  position: relative
</style>
