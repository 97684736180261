<template>
  <DialogContainer
    :icon="'icon-heart-minus'"
    :title="$translations['my-specialists'].dialog.title"
    :custom-icon="true"
    :icon-color="'red'"
    @closeDialog="closeDialog()"
  >
    <template v-slot:content>
      <div class="delete-favorite-buttons">
        <Button @clicked="deleteFavorite()" color="red" :text="$translations['my-specialists'].dialog.delete" />
        <Button @clicked="closeDialog()" type="outline" color="gray" :text="$translations['my-specialists'].dialog.cancel" />
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import { Button, DialogContainer, SpecialistFavorite } from '@seliaco/red-panda'
import { SpecialistProfileEvent } from '@/types/events'

export default {
  name: 'SpecialistFavoriteDialog',
  components: { DialogContainer, Button },
  props: {
    specialist: Object,
    favoriteId: String
  },
  methods: {
    closeDialog (payload) {
      this.$emit('close', payload)
    },
    deleteFavorite () {
      SpecialistFavorite.remove(this.favoriteId || this.specialist.favorite)
        .then(() => {
          this.$toast({
            text: this.$translations['my-specialists']['toast-delete'],
            severity: 'success'
          })
          SpecialistProfileEvent.removeFavorite({
            specialistId: this.specialist.id,
            specialistName: this.specialistName
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() => {
          this.closeDialog(true)
        })
    }
  },
  computed: {
    specialistName () {
      return this.specialist.user
        ? `${this.specialist.user.first_name} ${this.specialist.user.last_name}`
        : this.specialist.name
    }
  }
}
</script>

<style lang="sass" scoped>
.delete-favorite-buttons
  display: grid
  gap: 8px
</style>
