<template>
  <div class="modality-selector" v-if="options && valueData">
    <div
      @click="onOptionSelected(option)"
      v-for="(option, index) in options"
      :key="index"
      class="modality-selector-item"
      :class="getOptionStyles(option)"
    >
      <div
        class="modality-selector-item-icon"
        :class="option.icon"
        v-if="option.icon"
      />
      <div class="modality-selector-item-text content-title" v-text="option.text" />
    </div>
  </div>
</template>

<script>
/**
 * @DEPRECATED
 * This component is being replaced by the OptionSelector component
 */
export default {
  name: 'ModalitySelector',
  props: {
    value: String,
    presentialOption: Boolean,
    rescheduleOption: String
  },
  data () {
    return {
      valueData: null,
      online: {
        code: 'ONLINE',
        disabled: false,
        text: this.$translations.option.modality.online,
        icon: 'icon-video-outline-off'
      },
      inPerson: {
        code: 'FACE_TO_FACE',
        disabled: this.hasPresentationOption,
        text: this.$translations.option.modality['face-to-face'],
        icon: 'icon-person-off'
      }
    }
  },
  mounted () {
    const optionsData = this.getOptions()

    if (this.value && optionsData.some((o) => o.code === this.value)) {
      const value = optionsData.filter((o) => o.code === this.value)
      this.onOptionSelected(value[0])
    } else {
      this.onOptionSelected(optionsData[0])
    }
  },
  methods: {
    onOptionSelected (option) {
      this.valueData = option
      this.$emit('changed', option)
    },
    getOptionStyles (option) {
      if (this.rescheduleOption) {
        return this.rescheduleOption === option.code ? 'active' : 'hidden'
      }

      if (option.disabled) {
        return 'hidden'
      }

      if (option.code === this.valueData.code) {
        return 'active'
      }
    },
    getOptions () {
      if (this.presentialOption) {
        return [this.online, this.inPerson]
      } else {
        return [this.online]
      }
    }
  },
  computed: {
    options () {
      return this.getOptions()
    },
    hasPresentationOption () {
      return !this.presentialOption
    }
  }
}
</script>
<style lang="sass" scoped>
.modality-selector
  display: flex
  margin: 16px 0
  gap: 8px

  &-item
    padding: 8px 16px
    border-radius: 8px
    justify-content: center
    cursor: pointer
    display: flex
    transition: 300ms background-color, border-color, color, fill, stroke
    background: var(--gray-5)
    border: 2px solid var(--gray-20)
    color: var(--gray-80)
    gap: 4px
    align-items: center

    &-icon
      line-height: 1
      font-size: var(--xl-3)

    &.active
      color: var(--purple)
      background: var(--purple-5)
      border-color: var(--purple-50)
      box-shadow: var(--shadow-purple)
      .content-title
        color: var(--purple)

    &.disabled
      pointer-events: none

    &.hidden
      opacity: 0
</style>
