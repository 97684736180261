var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-card",on:{"click":_vm.handleSelectService}},[_c('div',{staticClass:"service-card-icon",style:({ background: _vm.serviceStyles[_vm.service.icon].background })},[_c('div',{class:_vm.serviceStyles[_vm.service.icon].iconName,style:({ color: _vm.serviceStyles[_vm.service.icon].color })})]),_c('div',{staticClass:"service-card-content"},[_c('div',{staticClass:"service-card-content-info"},[_c('div',{staticClass:"content-title service-card-content-info-title",domProps:{"innerHTML":_vm._s(_vm.cardText(_vm.service.title))}}),_c('div',{staticClass:"service-card-content-info-data"},[_c('div',{staticClass:"content-title duration",domProps:{"textContent":_vm._s(_vm.service.duration)}}),(_vm.version === 2 && _vm.service.max_package_discount > 0)?_c('div',{staticClass:"service-card-content-description body-small",domProps:{"textContent":_vm._s(
            _vm.$translations['specialist-page'].price['service-card'][
              'unit-cost'
            ]
          )}}):_vm._e(),(_vm.allowedByRole([_vm.roleType.USER, _vm.roleType.USER_B2B]))?[_c('div',{staticClass:"content-title",class:_vm.priceStyle,domProps:{"textContent":_vm._s(_vm.service.original_price)}})]:_vm._e()],2)]),(_vm.action)?_c('div',{staticClass:"icon-arrow-big-outline-right action"}):_vm._e(),(
        _vm.service.chip?.text &&
        _vm.service.original_price !== _vm.service.price &&
        _vm.allowedByRole([_vm.roleType.USER, _vm.roleType.USER_B2B])
      )?_c('div',{staticClass:"service-card-content-promo"},[_c('Chip',{staticClass:"chip",attrs:{"size":"small","color":_vm.chipColor,"text":_vm.service.chip.text}}),(_vm.service.package.uses_left > 0)?_c('UsagesCoins',{attrs:{"option":_vm.service,"iconName":_vm.service.icon,"max":_vm.service.package.max_uses,"remaining":_vm.service.package.uses_left}}):_c('div',{staticClass:"content-title price",domProps:{"textContent":_vm._s(_vm.service.price)}})],1):_vm._e(),(_vm.version === 2 && _vm.service.max_package_discount > 0)?_c('div',{staticClass:"service-card-content-package"},[_c('div',{staticClass:"body-small",domProps:{"textContent":_vm._s(
          _vm.$translations['specialist-page'].price['service-card'][
            'packages-cost'
          ]
        )}}),_c('div',{staticClass:"content-title price",domProps:{"textContent":_vm._s(_vm.packagePrice)}})]):_vm._e(),_c('div',{staticClass:"service-card-content-description body-small",domProps:{"textContent":_vm._s(_vm.service.description)}}),(_vm.version === 1 && _vm.service.max_package_discount > 0)?_c('Chip',{staticClass:"chip-version",attrs:{"color":"blue","text":_vm.chipText}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }